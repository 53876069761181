<template>
  <div
    class="template-page"
    :style="{
      backgroundImage:
        'url(' +
        require(`@/views/map/preview/static/temp_${tempId}/tempBg.jpg`) +
        ')',
    }"
  >
    <div class="template-close">
      <router-link
        class="template-close-link"
        replace
        to="/map/"
        :style="{
          backgroundImage:
            'url(' +
            require(`@/views/map/preview/static/temp_${tempId}/close.png`) +
            ')',
        }"
      >
      </router-link>
    </div>
    <div class="template-wrap" v-if="!loadding">
      <preview
        :tempId="tempId"
        :data="mapData"
        :pointData="pointArr1"
        :points="pointArr2"
        @mapReload="init"
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { mapDetail } from "@/api/map";
import preview from "./preview/index.vue";
export default {
  components: {
    preview,
  },
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const taskId = parseInt(route.query.id || 0);
    const tempId = ref(parseInt(route.query.temp || 1));

    const loadding = ref(true),
      mapData = ref({}),
      pointArr1 = ref([]),
      pointArr2 = ref([]);

    // 定位
    let positions = [];
    if (tempId.value == 1) {
      positions = [
        [
          {
            left: "10%",
            bottom: "22.7%",
          },
          {
            left: "30%",
            bottom: "19%",
          },
          {
            left: "50%",
            bottom: "30%",
          },
          {
            left: "70%",
            bottom: "36%",
          },
          {
            left: "90%",
            bottom: "23.4%",
          },
        ],
        [
          {
            left: "10%",
            bottom: "25.8%",
          },
          {
            left: "30%",
            bottom: "37%",
          },
          {
            left: "50%",
            bottom: "31%",
          },
          {
            left: "70%",
            bottom: "29.6%",
          },
          {
            left: "90%",
            bottom: "33%",
          },
        ],
      ];
    } else if (tempId.value == 2) {
      positions = [
        [
          {
            left: "10%",
            bottom: "36.7%",
          },
          {
            left: "30%",
            bottom: "25%",
          },
          {
            left: "50%",
            bottom: "34%",
          },
          {
            left: "70%",
            bottom: "25.4%",
          },
          {
            left: "90%",
            bottom: "24%",
          },
        ],
        [
          {
            left: "10%",
            bottom: "45.8%",
          },
          {
            left: "30%",
            bottom: "39%",
          },
          {
            left: "50%",
            bottom: "44%",
          },
          {
            left: "70%",
            bottom: "34.6%",
          },
          {
            left: "90%",
            bottom: "27%",
          },
        ],
      ];
    } else if (tempId.value == 3) {
      positions = [
        [
          {
            left: "10%",
            bottom: "17.7%",
          },
          {
            left: "30%",
            bottom: "19%",
          },
          {
            left: "50%",
            bottom: "16%",
          },
          {
            left: "70%",
            bottom: "25.1%",
          },
          {
            left: "90%",
            bottom: "13.4%",
          },
        ],
        [
          {
            left: "10%",
            bottom: "9.8%",
          },
          {
            left: "30%",
            bottom: "24%",
          },
          {
            left: "50%",
            bottom: "20%",
          },
          {
            left: "70%",
            bottom: "16.6%",
          },
          {
            left: "90%",
            bottom: "14%",
          },
        ],
      ];
    }

    const init = (nowStudyStage) => {
      mapDetail(taskId).then((res) => {
        if (res.ret === 0) {
          let d = res.data;
          let nowTime = new Date().getTime();
          tempId.value = d.mapTemplate;
          if (!nowStudyStage) {
            d.nowStudyStage = 1; // 当前学习关卡
          } else {
            d.nowStudyStage = nowStudyStage;
          }
          d.isTimeout = d.endTime ? nowTime > d.endTime * 1000 : false; // 已过截止日期

          // 逾期后可学 isOverdue 1 可学
          d.isOverdue === 1 && (d.isTimeout = false);

          let stageCycle = d.learnCycle == 1; // 关卡开启限制

          for (let i = 0; i < d.stages.length; i++) {
            let stage = d.stages[i];

            let isWithin = false; // 是否在周期内
            let startT = stage.startUnlock * 1000;
            let endT = stage.endUnlock * 1000;
            if (nowTime >= startT && nowTime <= endT) isWithin = true;

            if (!nowStudyStage && stage.complete == 1 && i < d.stages.length - 1) {
              d.nowStudyStage++;
            }
            if (d.learnOrder == 2) {
              if (d.isTimeout) {
                stage.learnFlag = stage.complete == 1;
              } else {
                stage.learnFlag = true;
              }
            } else {
              // ==1 阶段按顺序学习
              if (i == 0) {
                if (d.isTimeout) {
                  stage.learnFlag = stage.complete == 1;
                } else {
                  stage.learnFlag = true;
                }
              } else {
                if (stageCycle) {
                  if (isWithin) {
                    if (d.stages[i - 1].complete == 1) {
                      if (d.isTimeout) {
                        stage.learnFlag = stage.complete == 1;
                      } else {
                        stage.learnFlag = true;
                      }
                    }
                  } else {
                    if (stage.startUnlock == 0 && stage.endUnlock == 0) {
                      stage.learnFlag = d.stages[i - 1].complete == 1;
                    } else {
                      if (d.isTimeout) {
                        stage.learnFlag = stage.complete == 1;
                      }
                    }
                  }
                } else {
                  if (d.stages[i - 1].complete == 1) {
                    if (d.isTimeout) {
                      stage.learnFlag = stage.complete == 1;
                    } else {
                      stage.learnFlag = true;
                    }
                  }
                }
              }
            }
            stage.details = stage.details || [];
            for (let j = 0; j < stage.details.length; j++) {
              let detail = stage.details[j];
              if (stage.learnOrder == 2) {
                // 资源不按按顺序学习
                if (stage.learnFlag || detail.complete == 1) {
                  detail.learnFlag = true;
                }
              } else {
                // 资源按顺序学习
                if (j == 0) {
                  // 当前阶段可学&&当前阶段没有过期 || 当前阶段完成
                  if (stage.learnFlag || detail.complete == 1) {
                    detail.learnFlag = true;
                  }
                } else {
                  if (
                    // 学习地图没有过期 上一阶段完成
                    (!d.isTimeout && stage.details[j - 1].progress == 100) ||
                    // 学习地图过期 当前阶段完成
                    (d.isTimeout && detail.complete == 1)
                  ) {
                    detail.learnFlag = true;
                  }
                }
              }
            }
          }
          mapData.value = d;
          let { arr1, arr2 } = mapDataRender(d);
          pointArr1.value = arr1;
          pointArr2.value = arr2;
          loadding.value = false;
        } else {
          router.replace({
            path: `/error`,
            query: {
              title: res.msg,
              path: "/map",
              name: $t("Pub_LP"),
            },
          });
        }
      });
    };

    const mapDataRender = (data) => {
      // 锚点
      let pointData = [
        { stageId: -1, stageName: $t("cm_start") },
        // 开始
        ...data.stages,
        { stageId: -2, stageName: $t("cm_end") },
        // 结束
      ];
      let points = [];
      let typesTotal = {
        document: 0,
        course: 0,
        exam: 0,
        questionnaire: 0,
        homework: 0,
        teach: 0,
      };
      for (let i = 0; i < pointData.length; i++) {
        let page = Math.floor(i / 5);
        pointData[i].mapPage = page;
        pointData[i].position = positions[page % 2][i % 5];
        pointData[i].details = pointData[i].details || [];
        for (let j = 0; j < pointData[i].details.length; j++) {
          let resourceType = pointData[i].details[j].resourceType;
          switch (resourceType) {
            case 1:
              typesTotal.document++;
              break;
            case 2:
              typesTotal.course++;
              break;
            case 3:
              typesTotal.exam++;
              break;
            case 4:
              typesTotal.questionnaire++;
              break;
            case 5:
              typesTotal.homework++;
              break;
            case 43:
              typesTotal.teach++;
              break;
          }
        }
      }
      data.typesTotal = typesTotal;
      if (pointData.length <= 5) {
        pointData[pointData.length - 1].position = positions[0][4];
      }
      for (let i = 0; i < pointData.length; i += 5) {
        points.push(pointData.slice(i, i + 5));
      }
      return {
        arr1: pointData,
        arr2: points,
      };
    };

    init();

    return {
      tempId,
      loadding,
      mapData,
      pointArr1,
      pointArr2,
      init,
    };
  },
};
</script>

<style lang="less" scoped>
.template-page {
  width: 100vw;
  height: 100vh;
  min-width: 1376px;
  min-height: 778px;
  background-size: cover;
  .mixinFlex(center; center);
  .template-close {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    position: fixed;
    top: -100px;
    right: -100px;
    background: rgba(0, 0, 0, 0.05);
    z-index: 10;
    &-link {
      width: 39px;
      height: 39px;
      position: absolute;
      left: 42px;
      bottom: 42px;
      transition: all 0.2s;
    }
    &:hover {
      .template-close-link {
        background-position-y: 39px;
      }
    }
  }
  .template-wrap {
    width: 1376px;
    height: 778px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 4px 0 8px rgba(255, 255, 255, 0.16);
  }
}
</style>
